import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  SvgIcon,
  Typography,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { ReactComponent as Cancel } from '../../assets/icons/cancel.svg';
import { ReactComponent as SaveChanges } from '../../assets/icons/save-changes.svg';
import { useManageOrganisation } from '../../hooks/services';
import { ORGANISATIONS_ADMIN_ROUTE } from '../../routes/Types';
import { LAYOUT } from '../../utils/constants/layout.constants';
import { OrganisationFormFields } from '../../utils/types/organisation';
import StyledInput from '../common/StyledInput';
import Title from '../common/Title';
import TopPannel from '../common/TopPannel';
import OrganisationAddSuccess from './OrganisationAddSuccess';
import { validationSchema } from './Schema';

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: 64,
    background: theme.palette.background.paper,
    [theme.breakpoints.up('md')]: {
      marginTop: LAYOUT.TopPanelHeight + LAYOUT.TopPanelBottomMargin,
      width: `calc(100% - ${LAYOUT.DrawerWidth}px)`,
      marginLeft: LAYOUT.DrawerWidth,
    },
  },
  title: {
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(5),
  },
  icon: {
    paddingRight: theme.spacing(2),
  },
  button: {
    height: 40,
    borderRadius: 10,
    justifyContent: 'flex-start',
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(4),
    marginBottom: theme.spacing(3),
  },
  input: {
    'label + &': {
      marginTop: 0,
      color: theme.palette.text.secondary,
    },
  },
  errorMessage: {
    height: theme.spacing(6),
  },
}));

const defaultValues: OrganisationFormFields = {
  name: '',
  address_line_1: '',
  address_line_2: '',
  address_line_3: '',
  city: '',
  post_code: '',
  country: '',
  region: '',
  focus_centre_enabled: false,
};

const OrganisationAddContent = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMediaQuery = useMediaQuery(theme.breakpoints.up('md'), {
    noSsr: true,
  });
  const history = useHistory();
  const [showSuccess, setShowSuccess] = useState(false);

  const handleRedirectLinkClick = () => {
    history.push(`${ORGANISATIONS_ADMIN_ROUTE}`);
  };

  const {
    handleSubmit,
    formState: { errors, isDirty },
    control,
    reset,
  } = useForm<OrganisationFormFields>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const {
    mutate: addOrganisation,
    isLoading,
    isError,
    isSuccess,
  } = useManageOrganisation('create');

  const onSubmit = (data: OrganisationFormFields) => {
    //Optional fields are not required and commented out due to the API not accepting them. We can add them back when the API is updated.
    const formData = {
      organization: {
        name: data.name,
        address_line_1: data.address_line_1 || '',
        address_line_2: data.address_line_2 || null,
        address_line_3: data.address_line_3 || null,
        city: data.city || '',
        post_code: data.post_code || '',
        country: data.country || '',
        region: data.region || '',
        focus_centre_enabled: data.focus_centre_enabled,
      },
    };
    addOrganisation(formData, {
      onSuccess: () => {
        setShowSuccess(true);
        reset();
      },
    });
  };

  return (
    <>
      {showSuccess ? (
        <OrganisationAddSuccess setShowSuccess={() => setShowSuccess(false)} />
      ) : (
        <Box className={classes.content}>
          {matchesMediaQuery ? (
            <TopPannel>
              <Title
                onRedirectLinkClick={handleRedirectLinkClick}
                pageName={'Back to all organisations'}
              />
            </TopPannel>
          ) : (
            <Box textAlign="left" mr={4} ml={4}>
              <Title
                onRedirectLinkClick={handleRedirectLinkClick}
                pageName={'Back to all organisations'}
              />
            </Box>
          )}

          <Box
            ml={{ xs: 4, sm: 4, md: 4, lg: 10 }}
            mr={{ xs: 4, sm: 4, md: 4, lg: 20 }}
            mb={10}
          >
            <Typography
              variant="h4"
              color="textPrimary"
              align="left"
              classes={{ root: classes.title }}
            >
              Add Organisation
            </Typography>
            <Divider />
            <Grid container direction="row">
              <Grid item xs={12} sm={12} md={7}>
                {/* error message start*/}
                {isError && (
                  <Box mt={8} textAlign={'left'}>
                    <Typography variant={'subtitle2'} color={'error'}>
                      {'Error creating organisation'}
                    </Typography>
                  </Box>
                )}
                {/* error message end*/}
                <Box mt={8}>
                  <Box>
                    <Box mb={5}>
                      <Typography variant="h5" color="primary" align="left">
                        {'Name'}
                      </Typography>
                    </Box>
                    <StyledInput
                      name="name"
                      label="Organisation name"
                      control={control}
                      error={!!errors.name}
                      classnames={{ formControl: classes.input }}
                    />
                    {errors.name && (
                      <Typography
                        variant="body1"
                        color="error"
                        className={classes.errorMessage}
                        align="left"
                      >
                        {errors.name.message}
                      </Typography>
                    )}
                  </Box>
                  <Box mt={7}>
                    <Box mb={5}>
                      <Typography variant="h5" color="primary" align="left">
                        {'Office Address'}
                      </Typography>
                    </Box>
                    <StyledInput
                      name="address_line_1"
                      label="Address line 1"
                      control={control}
                      error={!!errors.address_line_1}
                      classnames={{ formControl: classes.input }}
                    />
                    {errors.address_line_1 && (
                      <Typography
                        variant="body1"
                        color="error"
                        className={classes.errorMessage}
                        align="left"
                      >
                        {errors.address_line_1.message}
                      </Typography>
                    )}
                  </Box>
                  <Box mt={7}>
                    <StyledInput
                      name="address_line_2"
                      label="Address line 2"
                      control={control}
                      error={!!errors.address_line_2}
                      classnames={{ formControl: classes.input }}
                    />
                    {errors.address_line_2 && (
                      <Typography
                        variant="body1"
                        color="error"
                        className={classes.errorMessage}
                        align="left"
                      >
                        {errors.address_line_2.message}
                      </Typography>
                    )}
                  </Box>
                  <Box mt={7}>
                    <StyledInput
                      name="address_line_3"
                      label="Address line 3"
                      control={control}
                      error={!!errors.address_line_3}
                      classnames={{ formControl: classes.input }}
                    />
                    {errors.address_line_3 && (
                      <Typography
                        variant="body1"
                        color="error"
                        className={classes.errorMessage}
                        align="left"
                      >
                        {errors.address_line_3.message}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box mt={7}>
                  <StyledInput
                    name="city"
                    label="City"
                    control={control}
                    error={!!errors.city}
                    classnames={{ formControl: classes.input }}
                  />
                  {errors.city && (
                    <Typography
                      variant="body1"
                      color="error"
                      className={classes.errorMessage}
                      align="left"
                    >
                      {errors.city.message}
                    </Typography>
                  )}
                </Box>
                <Box mt={7}>
                  <StyledInput
                    name="post_code"
                    label="Post code"
                    control={control}
                    error={!!errors.post_code}
                    classnames={{ formControl: classes.input }}
                  />
                  {errors.post_code && (
                    <Typography
                      variant="body1"
                      color="error"
                      className={classes.errorMessage}
                      align="left"
                    >
                      {errors.post_code.message}
                    </Typography>
                  )}
                </Box>
                <Box mt={7}>
                  <StyledInput
                    name="country"
                    label="Country"
                    control={control}
                    error={!!errors.country}
                    classnames={{ formControl: classes.input }}
                  />
                  {errors.country && (
                    <Typography
                      variant="body1"
                      color="error"
                      className={classes.errorMessage}
                      align="left"
                    >
                      {errors.country.message}
                    </Typography>
                  )}
                </Box>
                <Box mt={7}>
                  <StyledInput
                    name="region"
                    label="Region"
                    control={control}
                    error={!!errors.region}
                    classnames={{ formControl: classes.input }}
                  />
                  {errors.region && (
                    <Typography
                      variant="body1"
                      color="error"
                      className={classes.errorMessage}
                      align="left"
                    >
                      {errors.region.message}
                    </Typography>
                  )}
                </Box>
                <Box
                  mt={7}
                  display={'flex'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                >
                  <Typography variant="subtitle1" color="textPrimary">
                    Focus Centre
                  </Typography>
                  <Controller
                    control={control}
                    name="focus_centre_enabled"
                    render={({ field: { onChange, value } }) => (
                      <RadioGroup
                        aria-label="focus_centre_enabled"
                        value={value}
                        onChange={(e) => onChange(e.target.value === 'true')}
                        name="Focus Centre Enabled"
                        row
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio color="primary" />}
                          label={'Show'}
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio color="primary" />}
                          label={'Hide'}
                        />
                      </RadioGroup>
                    )}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <Box mt={8} ml={{ xs: 4, sm: 4, md: 4, lg: 15 }}>
                  <Box display="flex" mt={5}>
                    <Box mr={2}>
                      <Button
                        color="secondary"
                        disabled={isLoading}
                        variant="contained"
                        disableElevation
                        onClick={handleRedirectLinkClick}
                        classes={{ root: classes.button }}
                      >
                        <SvgIcon
                          component={Cancel}
                          classes={{ root: classes.icon }}
                        ></SvgIcon>
                        Cancel
                      </Button>
                    </Box>

                    <Button
                      type="submit"
                      disabled={isLoading || isSuccess || !isDirty}
                      color="secondary"
                      variant="contained"
                      disableElevation
                      onClick={handleSubmit(onSubmit)}
                      classes={{ root: classes.button }}
                    >
                      <SvgIcon
                        component={SaveChanges}
                        classes={{ root: classes.icon }}
                      ></SvgIcon>
                      Save changes
                    </Button>
                  </Box>
                  <Box mt={5}>{isLoading && <CircularProgress />}</Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
    </>
  );
};

export default OrganisationAddContent;
