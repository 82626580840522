import {
  createContext,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { useOrganisation } from '../hooks/services';
import {
  USERS_ACTIONS,
  UsersReducer,
  UsersState,
  initialUsersState,
} from '../reducers/users';
import { updateOrganisationUser } from '../services/organisations';
import {
  archiveUserFirebase,
  changeRoleFirebase,
  fetchOrganisationUsers,
  getAllFirebase,
  getUserDetailsFirebase,
  resetUserDeviceIdFirebase,
  resetUserMFA,
  triggerSendEmail,
  updateUserFirebase,
} from '../services/users';
import { UserFilters } from '../utils/constants/userFilters.constants';
import { User } from '../utils/types/user';

interface UsersContext {
  state: UsersState;
  filteredUsers?: User[];
  filter: UserFilters;
  resetLinkCopied: boolean;
  updateUserFilters: (filters: UserFilters) => void;
  onKeywordChange: (value: string) => void;
  getUserDetails: (id: string) => void;
  updateUser: (user: User, orgOrganizationId?: number) => void;
  archiveUser: (user: any, archived: boolean) => void;
  changeRole: (user: any, role: string) => void;
  resetDevice: (user: any) => void;
  migrateUserInvites: () => void;
  getFilteredData: (filters: UserFilters) => void;
  updateResetLinkCopied: (status: boolean) => void;
  fetchAndMergeOrganisationUsers: (organizationId: number) => void;
  resetMFA: (user: any) => void;
}

export const UsersStore = createContext<UsersContext>({
  state: initialUsersState,
  filter: {
    company: '',
    jobTitle: '',
    role: '',
    clientTypes: [],
    regions: [],
    keywords: '',
  },
  filteredUsers: undefined,
  resetLinkCopied: false,
  updateUserFilters: () => {},
  archiveUser: (_user: any, _archived: boolean) => {},
  onKeywordChange: (_value: string) => {},
  getUserDetails: async (_id: string) => {},
  updateUser: async (_user: User, _orgOrganizationId?: number) => {},
  changeRole: (_user: any, _role: string) => {},
  resetDevice: (_user: any) => {},
  migrateUserInvites: () => {},
  getFilteredData: () => {},
  updateResetLinkCopied: () => {},
  fetchAndMergeOrganisationUsers: () => {},
  resetMFA: () => {},
});

const { Provider } = UsersStore;

export const UsersProvider = ({ children }: any) => {
  const { organisation } = useOrganisation();
  const [state, dispatch] = useReducer(UsersReducer, initialUsersState);
  const [filter, setFilter] = useState<UserFilters>({
    keywords: '',
    company: '',
    jobTitle: '',
    role: '',
    clientTypes: [],
    regions: [],
  });
  const [filteredUsers, setFilterUsers] = useState<User[] | undefined>(
    undefined
  );
  const [fullDataFetched, setFullDataFetched] = useState(false);
  const [resetLinkCopied, setResetLinkCopied] = useState<boolean>(false);

  useEffect(() => {
    if (organisation?.id) {
      fetchAndMergeOrganisationUsers(organisation.id);
    }
  }, [organisation?.id]);

  const updateUserFilters = useCallback(
    (filters) => {
      setFilter(filters);
      getFilteredData(filters);
    },
    [setFilter]
  );

  const searchUsers = useCallback(
    (keywords: string, data: User[]) => {
      const kws = keywords.split(' ').map((kw) => kw.trim());

      const _filteredUsers = data.filter((user) => {
        let res = false;
        for (let i = 0; i < kws.length; i++) {
          if (
            user?.firstName?.toLowerCase().indexOf(kws[i].toLowerCase()) > -1 ||
            user?.lastName?.toLowerCase().indexOf(kws[i].toLowerCase()) > -1 ||
            user?.email?.toLowerCase().indexOf(kws[i].toLowerCase()) > -1
          ) {
            res = true;
          }
        }

        return res;
      });
      return _filteredUsers;
    },
    [filter, state]
  );

  const getFilteredData = useCallback(
    async (filters: UserFilters) => {
      let _filteredUsers: User[] = state.data ? [...state.data] : [];

      //if full data is not fetched - fetch it here, else use data from store for the filtering
      if (!fullDataFetched) {
        dispatch({ type: USERS_ACTIONS.FETCH });

        const allUsers = await getAllFirebase();
        _filteredUsers = [...allUsers];
        setFullDataFetched(true);

        dispatch({
          type: USERS_ACTIONS.FETCH_DATA_SUCCESS,
          loading: false,
          data: allUsers,
        });
      }

      //if there is active search, filter the data
      if (filters.keywords) {
        _filteredUsers = searchUsers(filters.keywords, _filteredUsers);
      }

      if (filters.company) {
        _filteredUsers = _filteredUsers.filter(
          (user) =>
            user.company &&
            user.company.toLocaleLowerCase() ===
              filters.company.toLocaleLowerCase()
        );
      }

      if (filters.jobTitle) {
        _filteredUsers = _filteredUsers.filter(
          (user) =>
            user.jobTitle &&
            user.jobTitle
              .toLocaleLowerCase()
              .includes(filters.jobTitle.toLocaleLowerCase())
        );
      }

      if (filters.role) {
        _filteredUsers = _filteredUsers.filter(
          (user) =>
            user.role &&
            user.role.toLocaleLowerCase() === filters.role.toLocaleLowerCase()
        );
      }

      if (filters.regions && filters.regions.length > 0) {
        const regionValues = filters.regions.map((region) => region.id);
        _filteredUsers = _filteredUsers.filter((user) =>
          regionValues.some((v) => {
            const userRegions = user.regions ? user.regions.split(', ') : [];
            return userRegions.indexOf(v) >= 0;
          })
        );
      }

      if (filters.clientTypes && filters.clientTypes.length > 0) {
        const clientTypesValues = filters.clientTypes.map((ct) => ct.id);
        _filteredUsers = _filteredUsers.filter((user) =>
          clientTypesValues.some((v) => {
            const userClientTypes = user.clientTypes
              ? user.clientTypes.split(', ')
              : [];
            return userClientTypes.indexOf(v) >= 0;
          })
        );
      }

      setFilterUsers(_filteredUsers);
    },
    [state.data, setFilterUsers]
  );

  const updateResetLinkCopied = (status: boolean) => {
    setResetLinkCopied(status);
  };

  const migrateUserInvites = useCallback(async () => {
    /*
    //Function to migrate invites expiry date to user expiry date. Leaving it here for reference.
    const registeredInvites = await getAllRegisteredInvitesFirebase();
    console.log('Migrate', state.data);
    state?.data?.forEach((user: User) => {
      console.log(user.email, user.expiry);
      return ;
      const userInvite = registeredInvites.find((invite: User) => invite.email === user.email);
      if(!user.expiry) {
        user.expiry = userInvite?.expiry ?? new Date('2022/12/31');
        console.log(user.id, userInvite?.id, user.expiry);

        updateUserFirebase({
          ...user,
          assetClasses: undefined,
          clientTypes: undefined,
          strategies: undefined,
          regions: undefined,
        });
      }
    });
    */
  }, [state.data]);

  const getUserDetails = useCallback(
    async (id: string) => {
      dispatch({ type: USERS_ACTIONS.FETCH_DETAILS });
      let _user = state?.data?.find((user: User) => user.id === id);
      if (filteredUsers) {
        _user = filteredUsers.find((user: User) => user.id === id);
      }
      if (_user?.email) {
        dispatch({
          type: USERS_ACTIONS.FETCH_DETAILS_SUCCESS,
          loading: false,
          data: _user,
        });
      }
    },
    [state?.data, filteredUsers]
  );

  const updateUser = useCallback(
    async (user: User, orgOrganizationId?: number) => {
      dispatch({ type: USERS_ACTIONS.UPDATE_START });
      const _updatedOrganisationUser = await updateOrganisationUser(user.id, {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        license: user.license,
        access_level: user.access_level,
        company: user.company,
        officeAddress: user.officeAddress,
        organization_role: user.organization_role,
        license_expires_at: user.license_expires_at,
        disabled: user.disabled,
        organization_id: user.organization_id,
      });

      await updateUserFirebase(user);

      // await triggerSendEmail(
      //   'Fundpath Account Changes',
      //   user.email,
      //   'Your profile has been updated.',
      //   '7dnvo4dd8q645r86',
      //   [
      //     {
      //       var: 'first_name',
      //       value: user.firstName,
      //     },
      //     { var: 'last_name', value: user.lastName },
      //     { var: 'email', value: user.email },
      //     { var: 'licence', value: user.permission || 'No licence' },
      //     { var: 'company_name', value: user.company },
      //     {
      //       var: 'organisation_role',
      //       value: updatedOrganisationUser.organization_role,
      //     },
      //     {
      //       var: 'subscription_expiration',
      //       value: format(user.expiry || new Date(), 'MMMM d, yyyy.'),
      //     },
      //   ]
      // );

      dispatch({ type: USERS_ACTIONS.UPDATE_SUCCESS, loading: false });
      dispatch({
        type: USERS_ACTIONS.FETCH_DATA_SUCCESS,
        loading: false,
      });

      await fetchAndMergeOrganisationUsers(
        orgOrganizationId ? orgOrganizationId : user.organization_id || 0
      );
    },
    [state.selectedUser]
  );

  const onKeywordChange = useCallback(
    (value: string) => {
      const updatedFilter = { ...filter, keywords: value };
      setFilter(updatedFilter);
      if (!value) {
        setFilterUsers(undefined);
        getFilteredData(updatedFilter);
      }
    },
    [setFilter, filter, getFilteredData, setFilterUsers]
  );

  const archiveUser = useCallback(
    async (user: any, archived: boolean) => {
      dispatch({
        type: USERS_ACTIONS.UPDATE_START,
      });

      const result = await archiveUserFirebase(user.id, archived);
      console.log('result Provider', result);
      if (!result.success) {
        alert('Error archiving user');
      } else {
        const updatedUsers = updateIsArchivedUser(
          state.data || [],
          user,
          archived
        );
        if (filteredUsers) {
          const updatedFilteredUsers = updateIsArchivedUser(
            filteredUsers,
            user,
            archived
          );
          setFilterUsers(updatedFilteredUsers);
        }

        dispatch({ type: USERS_ACTIONS.UPDATE_SUCCESS, loading: false });
        dispatch({
          type: USERS_ACTIONS.FETCH_DATA_SUCCESS,
          loading: false,
          data: updatedUsers,
        });
      }

      dispatch({
        type: USERS_ACTIONS.UPDATE_SUCCESS,
      });
    },
    [state.data, filteredUsers]
  );

  const updateUserPermisson = (
    users: User[],
    updatedUser: User,
    role: string
  ) => {
    const updatedUsers = users.map((user) => {
      if (user.id === updatedUser.id) {
        // Update permission property for the matching user
        return { ...user, permission: role };
      }
      return user;
    });
    return updatedUsers;
  };

  const updateIsArchivedUser = (
    users: User[],
    updatedUser: User,
    archived: boolean
  ) => {
    const updatedUsers = users.map((user) => {
      if (user.id === updatedUser.id) {
        return { ...user, archived: archived };
      }
      return user;
    });
    return updatedUsers;
  };

  const changeRole = useCallback(
    async (user: any, role: string) => {
      dispatch({
        type: USERS_ACTIONS.UPDATE_START,
      });

      const result = await changeRoleFirebase(user.id, role);

      if (!result.success) {
        alert('Error changing role');
      } else {
        const updatedUsers = updateUserPermisson(state.data || [], user, role);
        if (filteredUsers) {
          const updatedFilteredUsers = updateUserPermisson(
            filteredUsers,
            user,
            role
          );
          setFilterUsers(updatedFilteredUsers);
        }

        // if (role === 'pro') {
        //   await triggerSendEmail(
        //     'Fundpath Invite',
        //     user.email,
        //     'You have been invited to join Fundpath Pro',
        //     'neqvygmj5vdg0p7w',
        //     [
        //       {
        //         var: 'first_name',
        //         value: user.firstName,
        //       },
        //       { var: 'last_name', value: user.lastName },
        //       { var: 'company_name', value: user.company },
        //       {
        //         var: 'expiration_date',
        //         value: format(user.expiry || new Date(), 'MMMM d, yyyy.'),
        //       },
        //       {
        //         var: 'calendly_url',
        //         value: 'https://calendly.com/benjamin-martin-33',
        //       },
        //     ]
        //   );
        // }

        dispatch({ type: USERS_ACTIONS.UPDATE_SUCCESS, loading: false });
        dispatch({
          type: USERS_ACTIONS.FETCH_DATA_SUCCESS,
          loading: false,
          data: updatedUsers,
        });
      }

      dispatch({
        type: USERS_ACTIONS.UPDATE_SUCCESS,
      });
    },
    [state.data, filteredUsers]
  );

  const resetDevice = async (user: any) => {
    dispatch({
      type: USERS_ACTIONS.UPDATE_START,
    });
    await resetUserDeviceIdFirebase(user.id);
    await triggerSendEmail(
      'Fundpath Switch Device',
      user.email,
      'Switch Device',
      'z86org85k8zgew13',
      [
        {
          var: 'first_name',
          value: user.firstName,
        },
        { var: 'last_name', value: user.lastName },
      ]
    );
    alert('User device can be switched.');
    dispatch({
      type: USERS_ACTIONS.UPDATE_SUCCESS,
    });
  };

  const resetMFA = async (user: any) => {
    dispatch({
      type: USERS_ACTIONS.UPDATE_START,
    });
    await resetUserMFA(user.id);

    alert('User MFA reset successfully.');
    dispatch({
      type: USERS_ACTIONS.UPDATE_SUCCESS,
    });
  };

  const fetchAndMergeOrganisationUsers = useCallback(
    async (organizationId: number) => {
      dispatch({ type: USERS_ACTIONS.FETCH });

      try {
        // Fetch organization-specific users
        const organisation = await fetchOrganisationUsers(organizationId);

        // Fetch detailed user info for each organization user
        const userDetailPromises = organisation.map(async (orgUser) => {
          const userDetails = await getUserDetailsFirebase(orgUser.id);

          return {
            ...orgUser,
            ...userDetails,
          };
        });
        const organizationUsers = await Promise.all(userDetailPromises);

        // Merge organization data with detailed user data
        const mergedUsers = organizationUsers.map((user) => ({
          ...user,
          ...organisation.find((orgUser) => orgUser.id === user?.id),
        }));

        dispatch({
          type: USERS_ACTIONS.FETCH_DATA_SUCCESS,
          data: mergedUsers,
        });
      } catch (error: any) {
        console.error('Failed to fetch and merge organization users:', error);
        dispatch({
          type: USERS_ACTIONS.FETCH_DATA_FAIL,
          error: error.message,
        });
      }
    },
    []
  );

  return (
    <Provider
      value={{
        state,
        filteredUsers,
        filter,
        resetLinkCopied,
        getUserDetails,
        updateUser,
        archiveUser,
        onKeywordChange,
        changeRole,
        resetDevice,
        migrateUserInvites,
        getFilteredData,
        updateUserFilters,
        updateResetLinkCopied,
        fetchAndMergeOrganisationUsers,
        resetMFA,
      }}
    >
      {children}
    </Provider>
  );
};
