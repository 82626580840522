import { MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import { TooltipsStore } from '../../providers/tooltips';
import {
  TOOLTIPS_SORT_OPTIONS,
  TooltipsSort,
} from '../../utils/constants/tooltipsSort.constants';

interface TooltipsSortSelectProps {}

const useStyles = makeStyles(() => ({
  select: {
    paddingLeft: 0,
  },
}));

const TooltipsSortSelect: React.FC<
  TooltipsSortSelectProps
> = (): JSX.Element => {
  const classes = useStyles();
  const { sort, onSortChange } = useContext(TooltipsStore);

  return (
    <Select
      value={sort}
      onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
        onSortChange(e.target.value as TooltipsSort)
      }
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
      }}
      classes={{ select: classes.select }}
    >
      {TOOLTIPS_SORT_OPTIONS.map((option, i) => (
        <MenuItem key={i} value={option.value}>
          {option.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default TooltipsSortSelect;
