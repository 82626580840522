import { getAllInvitesForAllOrganisations } from '../../services/invites';
import { getAllFirebase } from '../../services/users';

const useCheckIfEmailExists = async (email: string) => {
  const allInvites = await getAllInvitesForAllOrganisations();
  const allUsers = await getAllFirebase();

  const matchingInvite = allInvites.find(
    (invite: any) => invite.email === email
  );
  const matchingUser = allUsers.find((user: any) => user.email === email);

  const exists = !!matchingInvite || !!matchingUser;
  const ownerId = matchingUser?.id || matchingInvite?.id || null; // Identify the owner

  return {
    exists,
    ownerId, // Return ID of user or invite if the email exists
    source: matchingUser ? 'user' : matchingInvite ? 'invite' : null, // Identify the source
  };
};

export default useCheckIfEmailExists;
