export type KnowledgeCentreSort =
  | 'title-asc'
  | 'title-desc'
  | 'published-asc'
  | 'published-desc';

export const KNOWLEDGE_CENTRE_SORT_OPTIONS = [
  {
    name: 'Alphabetical (asc)',
    value: 'title-asc',
  },
  {
    name: 'Alphabetical (desc)',
    value: 'title-desc',
  },
  {
    name: 'Last published (asc)',
    value: 'published-asc',
  },
  {
    name: 'Last published (desc)',
    value: 'published-desc',
  },
];
