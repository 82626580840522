import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  DocumentData,
  DocumentSnapshot,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  updateDoc,
} from '@firebase/firestore';
import { TooltipsSort } from '../utils/constants/tooltipsSort.constants';
import { Tooltip } from '../utils/types/tooltip';
import { db } from './firebase';

export interface TooltipsFilterType {
  keyword?: string;
}

export const createFirebase = async (tooltip: Tooltip): Promise<Tooltip> => {
  const tooltipsCollection = collection(db, 'tooltips');
  const createdDocRef = await addDoc(tooltipsCollection, tooltip);
  return { id: createdDocRef.id, ...tooltip };
};

export const updateFirebase = async (tooltip: Tooltip): Promise<Tooltip> => {
  if (!tooltip.id) throw new Error('Tooltip ID is undefined');
  const tooltipDocRef = doc(db, 'tooltips', tooltip.id);
  await updateDoc(tooltipDocRef, { ...tooltip, user: '' });
  return tooltip;
};

export const deleteFirebase = async (tooltip: Tooltip): Promise<Tooltip> => {
  if (!tooltip.id) throw new Error('Tooltip ID is undefined');
  const tooltipDocRef = doc(db, 'tooltips', tooltip.id);
  await deleteDoc(tooltipDocRef);
  return tooltip;
};

const transformKnowledgeCentre = (
  doc: DocumentSnapshot<DocumentData>
): Tooltip => {
  const data = doc.data();
  if (!data) {
    throw new Error('Document data is undefined');
  }

  return {
    id: doc.id,
    title: data.title,
    tooltipKey: data.tooltipKey,
    rawText: data.rawText,
    text: data.text,
    created: new Date(data.created.seconds * 1000),
    updated: new Date(data.updated.seconds * 1000),
  };
};

export const getBatchFirebase = async (
  lastDoc: Tooltip | null,
  sort: TooltipsSort
): Promise<Array<Tooltip>> => {
  let tooltips;

  const [field, _sort] = sort.split('-');
  const sortType = _sort === 'desc' ? 'desc' : 'asc';

  const tooltipsCollection = collection(db, 'tooltips');

  try {
    const baseQuery = query(
      tooltipsCollection,
      orderBy(field, sortType),
      limit(20)
    );

    if (lastDoc && lastDoc.id) {
      const lastDocRef = doc(db, 'tooltips', lastDoc.id);
      const lastDocSnapshot = await getDoc(lastDocRef);
      tooltips = await getDocs(query(baseQuery, startAfter(lastDocSnapshot)));
    } else {
      tooltips = await getDocs(query(baseQuery));
    }

    return tooltips.docs.map((doc) => ({
      id: doc.id,
      title: doc.data().title,
      tooltipKey: doc.data().tooltipKey,
      rawText: doc.data().rawText,
      text: doc.data().text,
      created: new Date(doc.data().created.seconds * 1000),
      updated: new Date(doc.data().updated.seconds * 1000),
    }));
  } catch (error) {
    console.error('Error fetching tooltips batch:', error);
    throw error;
  }
};

export const getFullTooltips = async (): Promise<Array<Tooltip>> => {
  try {
    const glossaryCollection = collection(db, 'tooltips');
    const glossaryQuery = query(glossaryCollection, orderBy('created', 'desc'));
    const glossary = await getDocs(glossaryQuery);

    return glossary.docs.map((doc: DocumentSnapshot) =>
      transformKnowledgeCentre(doc)
    );
  } catch (error) {
    console.error('Error fetching full glossary:', error);
    throw error;
  }
};

/* export const uploadImageToFirebase = async (file: File): Promise<string> => {
  let storage = firebase.storage();
  let storageRef = storage.ref();

  const name = `glossary/${Date.now()}_${file.name.replace(/\s+/g, '_')}`;

  let uploadTask = storageRef.child(name).put(file);

  const promise = new Promise<string>((resolve, _reject) => {
    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      (_snapshot: any) => {
        //TODO: at some point show progress
        //let progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes)) * 100
      },
      (error) => {
        throw error;
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((url) => {
          resolve(url);
        });
      }
    );
  });

  return promise;
};
 */
