import {
  Box,
  Button,
  CircularProgress,
  Grid,
  makeStyles,
  SvgIcon,
  Typography,
} from '@material-ui/core';
import { useCallback, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as AddIcon } from '../../assets/icons/add-icon.svg';
import { KnowledgeCentreStore } from '../../providers/knowledgeCentre';
import * as ROUTES from '../../routes/Types';
import { LAYOUT } from '../../utils/constants/layout.constants';
import { KNOWLEDGE_CENTRE_PAGE } from '../../utils/constants/pageNames.constants';
import SectionTitle from '../common/SectionTitle';
import KnowledgeCentreList from './KnowledgeCentreList';
import KnowledgeCentreSearch from './KnowledgeCentreSearch';
import KnowledgeCentreSort from './KnowledgeCentreSort';

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: 64,
    [theme.breakpoints.up('md')]: {
      marginTop: LAYOUT.TopPanelHeight + LAYOUT.TopPanelBottomMargin,
      width: `calc(100% - ${LAYOUT.DrawerWidth}px)`,
      marginLeft: LAYOUT.DrawerWidth,
    },
  },
  fullsize: {
    height: `calc(100vh - ${LAYOUT.TopPanelHeight}px - ${LAYOUT.TopPanelBottomMargin}px)`,
  },
  itemOrder: {
    order: 1,
    height: '100%',
    [theme.breakpoints.up('md')]: {
      order: 'initial',
      height: 'auto',
    },
  },
  button: {
    borderRadius: 10,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
}));

const KnowledgeCentreContent = () => {
  const {
    state: { data, loading },
    getBatch,
  } = useContext(KnowledgeCentreStore);
  const classes = useStyles();

  let history = useHistory();

  const handleAddClick = useCallback(() => {
    history.push(ROUTES.KNOWLEDGE_CENTRE_ADD_ROUTE);
  }, [history]);

  useEffect(() => {
    getBatch(null);
  }, []);

  return (
    <Box className={classes.content}>
      <SectionTitle title={KNOWLEDGE_CENTRE_PAGE} />
      <Box
        mr={{ xs: 5, sm: 5, md: 0, lg: 5 }}
        ml={{ xs: 5, sm: 5, md: 0, lg: 5 }}
      >
        <Grid container direction="row" className={classes.fullsize}>
          <Grid item xs={12} sm={12} md={6} className={classes.itemOrder}>
            <Box height={'100%'}>
              {data && (
                <KnowledgeCentreList
                  itemsList={data}
                  loadMoreItemsHandler={getBatch}
                />
              )}
              {loading && <CircularProgress />}
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box
              display="flex"
              alignContent="left"
              ml={{ xs: 4, sm: 4, md: 4, lg: 25 }}
            >
              <Button
                variant="contained"
                color="secondary"
                disableElevation
                classes={{ root: classes.button }}
                component="label"
                onClick={handleAddClick}
              >
                <SvgIcon component={AddIcon}></SvgIcon>
                {'Add Article'}
              </Button>
            </Box>
            <Box mt={8} ml={{ md: 5, lg: 28 }} mr={{ md: 5, lg: 15 }}>
              <Typography variant="subtitle1" align="left">
                Find a term
              </Typography>
              <KnowledgeCentreSearch />
            </Box>
            <Box mt={8} ml={{ md: 5, lg: 28 }} mr={{ md: 5, lg: 15 }}>
              <Typography variant="subtitle1" align="left">
                Sort terms
              </Typography>
              <KnowledgeCentreSort />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default KnowledgeCentreContent;
