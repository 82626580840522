import { MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import { KnowledgeCentreStore } from '../../providers/knowledgeCentre';
import {
  KNOWLEDGE_CENTRE_SORT_OPTIONS,
  KnowledgeCentreSort,
} from '../../utils/constants/knowledgeCentre.contants';

interface KnowledgeCentreSortSelectProps {}

const useStyles = makeStyles(() => ({
  select: {
    paddingLeft: 0,
  },
}));

const KnowledgeCentreSortSelect: React.FC<
  KnowledgeCentreSortSelectProps
> = (): JSX.Element => {
  const classes = useStyles();
  const { sort, onSortChange } = useContext(KnowledgeCentreStore);

  return (
    <Select
      value={sort}
      onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
        onSortChange(e.target.value as KnowledgeCentreSort)
      }
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
      }}
      classes={{ select: classes.select }}
    >
      {KNOWLEDGE_CENTRE_SORT_OPTIONS.map((option, i) => (
        <MenuItem key={i} value={option.value}>
          {option.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default KnowledgeCentreSortSelect;
