import { Tooltip } from '../utils/types/tooltip';

export interface TooltipsState {
  data: Array<Tooltip> | null;
  contentImageUrl?: string;
  loading: boolean;
  updating: boolean;
  uploading: boolean;
  error: any;
}

export const initialTooltipsState: TooltipsState = {
  data: null,
  contentImageUrl: undefined,
  loading: false,
  updating: false,
  uploading: false,
  error: null,
};

export const TOOLTIPS_ACTIONS = {
  FETCH: 'TOOLTIPS/FETCH',
  FETCH_DATA_SUCCESS: 'TOOLTIPS/SUCCESS',
  FETCH_INITIAL_DATA_SUCCESS: 'KNOWLEDGE_CENTRE/INITIAL_DATA_SUCCESS',
  FETCH_DATA_FAIL: 'TOOLTIPS/FAIL',
  UPDATE_START: 'TOOLTIPS/UPDATE_START',
  UPDATE_SUCCESS: 'TOOLTIPS/UPDATE_SUCCESS',
  IMAGE_UPLOAD_START: 'TOOLTIPS/IMAGE_UPLOAD_START',
  IMAGE_UPLOAD_SUCCESS: 'TOOLTIPS/IMAGE_UPLOAD_SUCCESS',
  REMOVE_START: 'TOOLTIPS/REMOVE_START',
  REMOVE_SUCCESS: 'TOOLTIPS/REMOVE_SUCCESS',
};

export const TooltipsReducer = (
  state: TooltipsState,
  action: any
): TooltipsState => {
  switch (action.type) {
    case TOOLTIPS_ACTIONS.FETCH:
      return { ...state, loading: true };
    case TOOLTIPS_ACTIONS.FETCH_DATA_SUCCESS:
      return {
        data: (state.data || []).concat(action.data),
        updating: false,
        loading: false,
        uploading: false,
        error: null,
      };
    case TOOLTIPS_ACTIONS.FETCH_INITIAL_DATA_SUCCESS:
      return {
        ...initialTooltipsState,
        data: action.data,
      };
    case TOOLTIPS_ACTIONS.FETCH_DATA_FAIL:
      return {
        data: null,
        updating: false,
        loading: false,
        uploading: false,
        error: null,
      };
    case TOOLTIPS_ACTIONS.UPDATE_START:
      return { ...state, updating: true };
    case TOOLTIPS_ACTIONS.UPDATE_SUCCESS:
      return {
        data: [...action.data].sort((t1: Tooltip, t2: Tooltip) => {
          return t1.created > t2.created ? -1 : 1;
        }),
        updating: false,
        loading: false,
        uploading: false,
        error: null,
      };
    case TOOLTIPS_ACTIONS.IMAGE_UPLOAD_START:
      return { ...state, uploading: true };
    case TOOLTIPS_ACTIONS.IMAGE_UPLOAD_SUCCESS:
      return { ...state, uploading: false, ...action.data };
    case TOOLTIPS_ACTIONS.REMOVE_START:
      return { ...state, updating: true };
    case TOOLTIPS_ACTIONS.REMOVE_SUCCESS:
      return { ...state, data: action.data, updating: false };
    default:
      throw new Error();
  }
};
